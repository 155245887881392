var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Pie_de_página)?_c('div',[_c('div',{staticClass:"d-none d-md-block"},[_c('v-row',{staticClass:"lado_izquierdo"},[_c('v-col',{staticClass:"lado_izquierdo",staticStyle:{"padding-left":"15%","padding-top":"3%","padding-bottom":"4%","margin-right":"40px"}},[_c('v-row',[_c('v-img',{attrs:{"src":_vm.Pie_de_página.imagen_pc,"max-width":"100px"}})],1),_c('v-row',[_c('div',{staticClass:"texto",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"gris--text",domProps:{"innerHTML":_vm._s(_vm.Pie_de_página.texto)}})])]),_c('v-row',{staticStyle:{"margin-top":"30px"}},_vm._l((_vm.Navegador.rutas),function(ref,i){
var ruta = ref.ruta;
var texto = ref.texto;
return _c('v-col',{key:i,staticStyle:{"text-align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"bloque-ruta",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.dirigir_a(ruta)}}},[_c('v-row',{staticClass:"align-items-center justify-center",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"gris--text"},[_vm._v(_vm._s(texto))])]),_c('v-row',{staticClass:"align-items-center justify-center"},[(hover)?_c('div',{staticClass:"triangulo",staticStyle:{"width":"0","height":"0","border-left":"7.07px solid transparent","border-right":"7.07px solid transparent","border-bottom":"10px solid #e62e4b"}}):_c('div',{staticClass:"triangulo",staticStyle:{"width":"0","height":"0","border-left":"7.07px solid transparent","border-right":"7.07px solid transparent","border-bottom":"10px solid transparent"}}),(hover)?_c('div',{staticClass:"linea primario",staticStyle:{"width":"100%","height":"3px","border-radius":"0%"}}):_c('div',{staticClass:"linea transparent",staticStyle:{"width":"100%","height":"3px","border-radius":"0%"}})])],1)]}}],null,true)})],1)}),1)],1),_c('v-col',{staticClass:"color_izquiero_footer",staticStyle:{"padding-right":"15%","padding-left":"3%"}},[_c('v-row',{staticStyle:{"margin-top":"24%"}},[_c('h2',{staticClass:"primario--text"},[_vm._v("Contáctanos")])]),_c('v-row',{staticClass:"mt-4"},[_c('span',{staticClass:"gris--text"},[_vm._v(_vm._s(_vm.Pie_de_página.numero_de_contacto))])]),_c('v-row',{staticClass:"mt-4"},[_c('span',{staticClass:"gris--text"},[_vm._v(_vm._s(_vm.Pie_de_página.correo))])]),_c('v-row',{staticStyle:{"padding-top":"14%"}},_vm._l((_vm.Pie_de_página.redes_sociales),function(ref,i){
var enlace = ref.enlace;
var icono = ref.icono;
return _c('v-btn',{key:i,staticClass:"white mr-6",attrs:{"icon":"","dark":"","small":""},on:{"click":function($event){return _vm.abrir_enlace(enlace)}}},[_c('v-icon',{staticClass:"primario--text",attrs:{"dark":"","large":""}},[_vm._v(_vm._s(icono))])],1)}),1)],1)],1)],1),_c('div',{staticClass:"d-md-none lado_derecho"},[_c('div',{staticClass:"lado_izquierdo pa-4 pb-9"},[_c('v-row',{staticClass:"justify-center",staticStyle:{"padding-top":"3%"}},[_c('v-img',{attrs:{"src":_vm.Pie_de_página.imagen_pc,"max-width":"100px"}})],1),_vm._l((_vm.Navegador.rutas),function(ref,i){
var ruta = ref.ruta;
var texto = ref.texto;
return _c('v-row',{key:i,staticClass:"justify-center",staticStyle:{"margin-top":"50px","text-align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"bloque-ruta",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.dirigir_a(ruta)}}},[_c('v-row',{staticClass:"align-items-center justify-center",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"gris--text"},[_vm._v(_vm._s(texto))])]),_c('v-row',{staticClass:"align-items-center justify-center"},[(hover)?_c('div',{staticClass:"triangulo",staticStyle:{"width":"0","height":"0","border-left":"7.07px solid transparent","border-right":"7.07px solid transparent","border-bottom":"10px solid #e62e4b"}}):_c('div',{staticClass:"triangulo",staticStyle:{"width":"0","height":"0","border-left":"7.07px solid transparent","border-right":"7.07px solid transparent","border-bottom":"10px solid transparent"}}),(hover)?_c('div',{staticClass:"linea primario",staticStyle:{"width":"100%","height":"3px","border-radius":"0%"}}):_c('div',{staticClass:"linea transparent",staticStyle:{"width":"100%","height":"3px","border-radius":"0%"}})])],1)]}}],null,true)})],1)})],2),_c('div',{staticClass:"color_izquiero_footer pa-4"},[_c('v-row',{staticClass:"justify-center",staticStyle:{"margin-top":"4%"}},[_c('h2',{staticClass:"primario--text"},[_vm._v("Contáctanos")])]),_c('v-row',{staticClass:"mt-6 justify-center"},[_c('span',{staticClass:"gris--text"},[_vm._v(_vm._s(_vm.Pie_de_página.numero_de_contacto))])]),_c('v-row',{staticClass:"mt-6 justify-center"},[_c('span',{staticClass:"gris--text"},[_vm._v(_vm._s(_vm.Pie_de_página.correo))])]),_c('v-row',{staticClass:"justify-center",staticStyle:{"padding-top":"14%","padding-bottom":"9%"}},_vm._l((_vm.Pie_de_página.redes_sociales),function(ref,i){
var enlace = ref.enlace;
var icono = ref.icono;
return _c('v-btn',{key:i,staticClass:"white mr-6",attrs:{"icon":"","dark":"","small":""},on:{"click":function($event){return _vm.abrir_enlace(enlace)}}},[_c('v-icon',{staticClass:"primario--text",attrs:{"dark":"","large":""}},[_vm._v(_vm._s(icono))])],1)}),1)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }