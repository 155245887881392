<template lang="pug">
div(v-if='Pie_de_página')
  //-PC
  .d-none.d-md-block
    v-row(style='').lado_izquierdo
      v-col(style='padding-left: 15%; padding-top: 3%; padding-bottom: 4%; margin-right: 40px').lado_izquierdo
        v-row
          v-img(:src='Pie_de_página.imagen_pc' max-width='100px') 
        v-row
          .texto(style='width: 100%')
            p(v-html='Pie_de_página.texto').gris--text 
        v-row(style='margin-top: 30px')
          v-col(v-for='{ruta, texto}, i in Navegador.rutas' :key='i' style='text-align: center')
            v-hover(v-slot="{ hover }")
              .bloque-ruta(style='cursor: pointer' @click='dirigir_a(ruta)')
                v-row(style='text-align: center').align-items-center.justify-center
                  span.gris--text {{texto}}
                v-row.align-items-center.justify-center
                  .triangulo(v-if='hover' style='width: 0; height: 0; border-left: 7.07px solid transparent; border-right: 7.07px solid transparent; border-bottom: 10px solid #e62e4b')
                  .triangulo(v-else style='width: 0; height: 0; border-left: 7.07px solid transparent; border-right: 7.07px solid transparent; border-bottom: 10px solid transparent')
                  .linea.primario(v-if='hover' style=`width: ${(i===3) ? '10%' : '100%'}; height: 3px; border-radius: 0%`)
                  .linea.transparent(v-else style=`width: ${(i===3) ? '10%' : '100%'}; height: 3px; border-radius: 0%`)

      v-col(style='padding-right: 15%; padding-left: 3%').color_izquiero_footer
        v-row(style='margin-top: 24%')
          h2.primario--text Contáctanos
        v-row.mt-4
          span.gris--text {{Pie_de_página.numero_de_contacto}}
        v-row.mt-4
          span.gris--text {{Pie_de_página.correo}}
        v-row(style='padding-top: 14%')
          v-btn(v-for='{enlace, icono}, i in Pie_de_página.redes_sociales' icon dark small :key='i' @click='abrir_enlace(enlace)').white.mr-6
            v-icon(dark large).primario--text {{icono}}
  //-Móvil
  .d-md-none.lado_derecho
    .lado_izquierdo.pa-4.pb-9  
      v-row(style='padding-top: 3%').justify-center
        v-img(:src='Pie_de_página.imagen_pc' max-width='100px') 
      v-row(style='margin-top: 50px; text-align: center' v-for='{ruta, texto}, i in Navegador.rutas' :key='i').justify-center
          v-hover(v-slot="{ hover }")
            .bloque-ruta(style='cursor: pointer' @click='dirigir_a(ruta)')
              v-row(style='text-align: center').align-items-center.justify-center
                span.gris--text {{texto}}
              v-row.align-items-center.justify-center
                .triangulo(v-if='hover' style='width: 0; height: 0; border-left: 7.07px solid transparent; border-right: 7.07px solid transparent; border-bottom: 10px solid #e62e4b')
                .triangulo(v-else style='width: 0; height: 0; border-left: 7.07px solid transparent; border-right: 7.07px solid transparent; border-bottom: 10px solid transparent')
                .linea.primario(v-if='hover' style=`width: ${(i===3) ? '10%' : '100%'}; height: 3px; border-radius: 0%`)
                .linea.transparent(v-else style=`width: ${(i===3) ? '10%' : '100%'}; height: 3px; border-radius: 0%`)

    .color_izquiero_footer.pa-4
      v-row(style='margin-top: 4%').justify-center
        h2.primario--text Contáctanos
      v-row.mt-6.justify-center
        span.gris--text {{Pie_de_página.numero_de_contacto}}
      v-row.mt-6.justify-center
        span.gris--text {{Pie_de_página.correo}}
      v-row(style='padding-top: 14%; padding-bottom: 9%').justify-center
        v-btn(v-for='{enlace, icono}, i in Pie_de_página.redes_sociales' icon dark small :key='i' @click='abrir_enlace(enlace)').white.mr-6
          v-icon(dark large).primario--text {{icono}}
</template>
<script>
export default {
  data: ()=>({
    año: new Date().getFullYear()
  }),
  computed: {
    ruta_actual(){
      return this.$route.name
    },
  },
  methods: {
    abrir_enlace(enlace){
      window.open(enlace, '_blank')
    }
  },
}
</script>
<style lang="sass" scoped>
.fila-elementos
  padding: 130px
  display: flex
  flex-direction: row
  align-items: center
  .columna-logo
    padding: 50px
    flex-direction: column
  .columna-rutas
    padding-top: 46px
    padding-left: 160px
    align-items: center
    justify-content: center
    &[direccion='derecha']
      padding-top: 8px
      padding-left: 130px
    .fila-ruta
      flex-direction: column
      padding-bottom: 10px
      .texto
        h3
          color: white
        &[marcado='true']
          border-bottom: 1px solid red
  .columna-contacto
    padding-top: 106px
    padding-left: 90px
    flex-direction: column
    .fila-contacto
      padding-bottom: 40px
      flex-direction: column
      img
        &[indice='0']
          height: 25px
          width: 25px
        &[indice='1']
          width: 18px

      
</style>